// import auth from "../middlewares/auth";
export const jobRoutes = [
  /*{
    path: '/jobs',
    component: () => import(/!* webpackChunkName: "jobs" *!/ '../views/job/JobList'),
    name: 'jobs',
    meta: {
      middleware: [
        auth
      ]
    }
  },*/
];