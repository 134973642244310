import auth from "../middlewares/auth";
export const reportRoutes = [
  {
    path: '/reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/report/Report'),
    name: 'reports',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/reports/money',
    component: () => import(/* webpackChunkName: "reports" */ '../views/report/Money.vue'),
    name: 'reports_money',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/reports/number',
    component: () => import(/* webpackChunkName: "reports" */ '../views/report/Number.vue'),
    name: 'reports_number',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/reports/score',
    component: () => import(/* webpackChunkName: "reports" */ '../views/report/Score.vue'),
    name: 'reports_score',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/reports/truck',
    component: () => import(/* webpackChunkName: "reports" */ '../views/report/truck/Index.vue'),
    name: 'reports_truck',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];