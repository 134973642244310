import auth from "../middlewares/auth";
export const mvpRoutes = [
  {
    path: '/mvp/:type',
    component: () => import(/* webpackChunkName: "mvp" */ '../views/mvp/Index.vue'),
    name: 'mvp',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];