import auth from "../middlewares/auth";
export const maintenanceRoutes = [
  {
    path: '/maintenances/add',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/maintenance/MaintenanceForm'),
    name: 'maintenances_add',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/equipment_next_maintenances',
    component: () => import(/* webpackChunkName: "maintenance" */ '../views/maintenance/EquipmentNextMaintenance.vue'),
    name: 'equipment_next_maintenances',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];