<template>
  <section class="content mt-3">
    <div class="container-fluid">
      <div class="row" v-if="topWidget">
<!--        <div class="col-lg-3 col-6">
          &lt;!&ndash; small card &ndash;&gt;
          <div class="small-box bg-info" @click="$refs.walletHistoryModal.show('gross', topWidget.gross)">
            <div class="inner">
              <h3>${{ topWidget.gross.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">GROSS PAY WALLET</p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>-->
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small card -->
          <div class="small-box bg-success" @click="$refs.walletHistoryModal.show('bonus', topWidget.commission)">
            <div class="inner">
              <h3>${{ topWidget.commission.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">BONUS WALLET</p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small card -->
          <div class="small-box bg-gradient-blue" @click="$refs.walletHistoryModal.show('wow', topWidget.wow)">
            <div class="inner">
              <h3>${{ topWidget.wow.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">WOWS WALLET</p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <!-- ./col -->
        <div class="col-lg-3 col-6">
          <!-- small card -->
          <div class="small-box bg-danger" @click="$refs.walletHistoryModal.show('tip', topWidget.tip)">
            <div class="inner">
              <h3>${{ topWidget.tip.current.toFixed(2) }}</h3>

              <div class="row">
                <div class="col-md-12">
                  <p style="margin: 0">TIPS WALLETS</p>
                </div>
              </div>
            </div>
            <div class="icon">
              <i class="fas fa-dollar-sign"></i>
            </div>
          </div>
        </div>
        <!-- ./col -->
      </div>
    </div>
  </section>

  <wallet-history-modal ref="walletHistoryModal" />
</template>

<script>
import WalletHistoryModal from "@/components/modals/WalletHistoryModal.vue";
export default {
  components: {WalletHistoryModal},
  data() {
    return {
      topWidget: null
    }
  },
  created() {
    this.axios.get('top-widgets')
        .then((response) => {
          this.topWidget = response.data.data;
        })
  }
}
</script>