<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link"
           data-toggle="dropdown"
           href="#"
           @click="notificationSeen">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge"
                v-if="notifications.filter(n => !n.seen).length">{{ notifications.filter(n => !n.seen).length }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
             v-if="notifications.length">
          <template v-for="notification in notifications" :key="'notification_'+notification.id">
            <a href="#" :class="['dropdown-item', {'bg-gray-light': !notification.seen}]">
              <p>
                <i class="fas fa-dollar-sign mr-2" v-if="notification.type === 'commission'"></i>
                <i class="fas fa-star mr-2" v-else-if="notification.type === 'sc'"></i>
                {{ notification.notification }}
              </p>
              <span class="text-muted text-sm">{{ notification.created_at }}</span>
            </a>
            <div class="dropdown-divider"></div>
          </template>

          <router-link :to="{name: 'notifications'}"
                       class="dropdown-item dropdown-footer">See All Notifications</router-link>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           role="button"
           @click.prevent="logout">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      pusher: null,
      notifications: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
  created() {
    this.axios.get('/notifications')
      .then(response => {
        this.notifications = response.data.data;
      })
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          window.location.href = process.env.VUE_APP_BASE + 'login'
        });
    },
    notificationSeen() {
      this.axios.post('/notifications/seen');
    },
    subscribePusher() {
      let self = this;

      this.pusher = new window.Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER
      });

      let channel = this.pusher.subscribe(this.user.id + '');

      channel.bind('wallet', function(data) {
        self.notifications.unshift(data.message);
      });
    }
  },
  mounted() {
    if (this.user) {
      this.subscribePusher();
    }
  },
}
</script>