import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";
import { jobRoutes } from "@/routes/jobRoutes";
import { withdrawalRoutes } from "@/routes/withdrawalRoutes";
import { reportRoutes } from "@/routes/reportRoutes";
import { maintenanceRoutes } from "@/routes/maintenanceRoutes";
import { workRoutes } from "@/routes/workRoutes";
import { mvpRoutes } from "@/routes/mvpRoutes";
import { taskRoutes } from "@/routes/taskRoutes";

let routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/work/WorkList.vue'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  /*{
    path: '/profile',
    component: () => import(/!* webpackChunkName: "dashboard" *!/ '../views/profile/Profile.vue'),
    name: 'profile',
    meta: {
      middleware: [
        auth
      ]
    }
  },*/
  {
    path: '/notifications',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Notifications'),
    name: 'notifications',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
]

routes.push(...jobRoutes);
routes.push(...withdrawalRoutes);
routes.push(...reportRoutes);
routes.push(...maintenanceRoutes);
routes.push(...workRoutes);
routes.push(...mvpRoutes);
routes.push(...taskRoutes);

export default routes;
