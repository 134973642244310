<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link :to="{name: 'dashboard'}" class="brand-link">
      <img :src="baseUrl + 'img/logo.png'"
           alt="So Clean Logo">
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img :src="baseUrl + 'img/user2-160x160.jpg'"
               class="img-circle elevation-2" alt="User Image">
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ user ? user.name : '' }}</a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
<!--          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'profile'}" exact-active-class="active">
              <i class="nav-icon fas fa-user-alt"></i>
              <p>Profile</p>
            </router-link>
          </li>-->
<!--          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'reports'}" active-class="active">
              <i class="nav-icon fas fa-clipboard-check"></i>
              <p>Performance Report</p>
            </router-link>
          </li>-->
<!--          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'dashboard'}" exact-active-class="active">
              <i class="nav-icon fas fa-wallet"></i>
              <p>Wallet</p>
            </router-link>
          </li>-->
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'dashboard'}" active-class="active">
              <i class="nav-icon fas fa-clock"></i>
              <p>Work</p>
            </router-link>
          </li>
<!--          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'jobs'}" active-class="active">
              <i class="nav-icon fas fa-briefcase"></i>
              <p>Jobs</p>
            </router-link>
          </li>-->
<!--          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'maintenances_add'}" active-class="active">
              <i class="nav-icon fas fa-tools"></i>
              <p>Maintenance</p>
            </router-link>
          </li>-->
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'withdrawals'}" active-class="active">
              <i class="nav-icon fas fa-money-bill-wave-alt"></i>
              <p>Withdrawals</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'tasks'}" active-class="active">
              <i class="nav-icon fas fa-clipboard-check"></i>
              <p>Tasks</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'maintenances_add'}" active-class="active">
              <i class="nav-icon fas fa-plus"></i>
              <p>Add Maintenance</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'equipment_next_maintenances'}" active-class="active">
              <i class="nav-icon fas fa-clock"></i>
              <p>Next Maintenance</p>
            </router-link>
          </li>
          <li class="nav-header">Performance Report</li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'reports_money'}" active-class="active">
              <i class="nav-icon fas fa-dollar-sign"></i>
              <p>Money</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'reports_number'}" active-class="active">
              <i class="nav-icon fas fa-list-ol"></i>
              <p>Number</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'reports_score'}" active-class="active">
              <i class="nav-icon fas fa-star"></i>
              <p>Score</p>
            </router-link>
          </li>
          <li class="nav-header">MVPs</li>
          <li class="nav-item">
            <router-link class="nav-link"
                         :to="{name: 'mvp', params: {type: 'power'}}"
                         exact
                         active-class="active">
              <i class="nav-icon fas fa-star"></i>
              <p>Power Score MVP</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         :to="{name: 'mvp', params: {type: 'wow'}}"
                         exact
                         active-class="active">
              <i class="nav-icon fas fa-star"></i>
              <p>Wow Score MVP</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link"
                         :to="{name: 'mvp', params: {type: 'hourly_monty'}}"
                         exact
                         active-class="active">
              <i class="nav-icon fas fa-star"></i>
              <p>Hourly Monty MVP</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-chart-line"></i>
              <p>
                Reports
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="{name: 'reports_truck'}"
                             :class="['nav-link', {'active': ['reports_truck'].includes($route.name)}]"
                             active-class="active">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Truck</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getUser'
    })
  },
}
</script>