import auth from "../middlewares/auth";
export const taskRoutes = [
  {
    path: '/tasks',
    component: () => import(/* webpackChunkName: "tasks" */ '../views/task/TaskList.vue'),
    name: 'tasks',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];